export default {
  BUTTON_PRIMARY_CLASS: 'btn btn-primary',
  BUTTON_SECONDARY_CLASS: 'btn btn-default',

  FACET_PANEL_CLASS: 'cm_facet-panel__row',

  SEARCH_BOX_INPUT_CLASS: 'search-text form-control',
  SEARCH_BOX_BUTTON_CLASS: 'btn btn-default btn-inverse',
  SEARCH_BOX_PLACEHOLDER: 'Search part #...',
};
